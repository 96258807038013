import { render, staticRenderFns } from "./TrendArrow.vue?vue&type=template&id=efef421e&scoped=true"
import script from "./TrendArrow.vue?vue&type=script&lang=js"
export * from "./TrendArrow.vue?vue&type=script&lang=js"
import style0 from "./TrendArrow.vue?vue&type=style&index=0&id=efef421e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efef421e",
  null
  
)

export default component.exports
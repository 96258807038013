<template>
  <div id="debug-container" class="container border float-left">
    <div class="d-sm-none">breakpoint: xs</div>
    <div class="d-none d-sm-inline-block d-md-none">breakpoint: sm</div>
    <div class="d-none d-md-inline-block d-lg-none">breakpoint: md</div>
    <div class="d-none d-lg-inline-block d-xl-none">breakpoint: lg</div>
    <div class="d-none d-xl-inline-block d-xxl-none">breakpoint: xl</div>
    <div class="d-inline-block ml-5">
      container width: {{ containerWidth() }}
    </div>
    <div class="d-inline-block ml-5">window width: {{ windowWidth() }}</div>
  </div>
</template>

<script>
export default {
  created() {
    if (process.client) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.addEventListener('resize', this.onResize)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    windowWidth() {
      if (process.client) {
        return window.innerWidth
      }
      return 0
    },
    containerWidth() {
      if (process.client) {
        const container = document.getElementById('debug-container')

        return container ? container.getBoundingClientRect().width : 0
      }
      return 0
    },
    currentBreakPoint() {},
    onResize(e) {
      this.$forceUpdate()
    },
  },
}
</script>

<style></style>

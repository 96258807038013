var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container px-0 mt-3 float-left",staticStyle:{"min-height":"300px"},attrs:{"id":_vm.containerId}},[_c('div',{staticClass:"chart-header"},[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('svg',{attrs:{"id":_vm.chartId,"width":_vm.svgWidth,"height":_vm.svgHeight},on:{"mousemove":_vm.throttledMouseMoved,"mouseleave":_vm.mouseLeave}},[_c('g',{attrs:{"id":"myG","transform":_vm.translate(_vm.bounds.left, _vm.bounds.top)}},[_vm._l((_vm.data),function(d,i){return _c('circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: _vm.tooltipContent(d),
            placement: 'top',
            html: true,
            customClass: 'circle-tooltip',
            id: `tooltip-${d.index}`,
            trigger: 'manual',
            animation: false,
            interactive: false,
          }),expression:"{\n            title: tooltipContent(d),\n            placement: 'top',\n            html: true,\n            customClass: 'circle-tooltip',\n            id: `tooltip-${d.index}`,\n            trigger: 'manual',\n            animation: false,\n            interactive: false,\n          }"}],key:`circle-${i}`,attrs:{"cx":d.x,"cy":d.y,"r":_vm.bubbleRadius,"fill":_vm.getColor(d),"stroke":_vm.getStrokeColor(d),"stroke-width":_vm.getStrokeWidth(d)}})}),_vm._v(" "),_vm._l((_vm.cPoints),function(d,idx){return _c('WrappingSVGText',{key:idx,staticClass:"chart-label primary",attrs:{"x":d.x,"y":d.y + d.r,"content":d.name}})})],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
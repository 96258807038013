<template>
  <div class="no-break mb-4c">
    <div class="no-break d-flex flex-row">
      <TrendArrow
        :width="arrowSize"
        :height="arrowSize"
        :direction="trendDirection"
        :color="trendColor"
      />
      <div v-if="showTrendUnit" class="trend-indicator" :style="cssColor">
        {{ formatedPercentage }}
      </div>
    </div>

    <div class="trend-value">{{ valueWithUnit }}</div>
    <div class="chart-header-small">
      <h3>{{ title }}</h3>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'

import TrendArrow from '@/components/charts/startpage/TrendArrow'
import { colorBlue, colorRed, colorDeepGry } from '@/plugins/colors'
import { formatSignedNumber } from '@/plugins/format'

export default {
  components: {
    TrendArrow,
  },
  props: {
    title: { type: String, default: '' },
    changeInPercent: { type: Number, default: 0 },
    value: { type: Number, default: 0 },
    unit: { type: String, default: '' },
    showTrendUnit: {
      type: Boolean,
      default: true,
    },
    noColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      directions: ['up', 'horizontal', 'down'],
      colors: [colorBlue, colorDeepGry, colorRed],
      signDomain: [1, 0, -1],
      arrowSize: '16px',
    }
  },
  computed: {
    colScale() {
      return d3.scaleOrdinal().domain(this.signDomain).range(this.colors)
    },
    dirScale() {
      return d3.scaleOrdinal().domain(this.signDomain).range(this.directions)
    },
    getColor() {
      return function (d) {
        const sign = this.signScale(d)
        return this.colScale(sign)
      }
    },
    signScale() {
      return function (d) {
        if (d > 0) {
          return 1
        } else if (d < 0) {
          return -1
        } else return 0
      }
    },
    getDirection() {
      return function (d) {
        const sign = this.signScale(d)
        return this.dirScale(sign)
      }
    },
    valueWithUnit() {
      return this.value + ' ' + this.unit
    },
    formatedPercentage() {
      return this.signedPercentage + ' ' + this.trendUnit
    },
    trendUnit() {
      return this.showTrendUnit ? '%' : ''
    },
    signedPercentage() {
      return formatSignedNumber(this.changeInPercent)
    },
    trendDirection() {
      const dir = this.getDirection(this.changeInPercent)
      return dir
    },
    trendColor() {
      return this.noColor ? colorDeepGry : this.getColor(this.changeInPercent)
    },
    cssColor() {
      return `color: ${this.trendColor}`
    },
  },
}
</script>

<style scoped>
.trend-value {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
}
.trend-indicator {
  margin-left: 4px;
  /* pull it up to title */
  margin-top: -1px;
  font-size: 1rem;
  line-height: normal;
  font-weight: 600;
}
</style>

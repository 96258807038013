<template>
  <div :class="styleClass">
    <div class="row-splitter">
      <p class="splitter-list d-flex flex-column">
        <a
          v-for="f in files"
          :key="f.name"
          class="splitter"
          :href="f.url"
          target="_blank"
        >
          {{ createLabel(f.name) }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    styleClass: {
      type: String,
      default: 'downloads',
    },
  },
  computed: {
    downloadLabel() {
      return this.$i18n.t('xls_download')
    },
  },
  methods: {
    createLabel(name) {
      return `${this.downloadLabel} ${name} [xlsx]`
    },
  },
}
</script>

<style></style>

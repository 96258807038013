<template>
  <div>
    <div class="row-splitter">
      <p class="splitter-list d-flex flex-column">
        <a
          v-for="l in activeLinks"
          :key="l.name"
          class="splitter"
          :href="l.url"
          target="_blank"
        >
          {{ createLabel(l.title) }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: { type: Array, default: () => [] },
  },
  computed: {
    activeLinks() {
      return this.links.filter((l) => {
        return l.active
      })
    },
  },
  methods: {
    createLabel(s) {
      return `${s}`
    },
  },
}
</script>

<style></style>

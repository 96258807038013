<template>
  <div class="img-teaser" @click="goToPage">
    <div
      class="img-galery"
      :style="{ backgroundImage: `url(${imageUrl})` }"
    ></div>
    <h5>{{ label }}</h5>
  </div>
</template>

<script>
// import backgroundUrl from '~/assets/img/kennzahlen.png'

export default {
  props: {
    to: {
      type: String,
      default: '/',
    },
    label: {
      type: String,
      default: 'no title',
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },

  methods: {
    goToPage() {
      console.log('click')
      this.$router.push(this.to)
    },
  },
}
</script>

<style scoped>
.img-teaser:hover > .img-galery::before {
  opacity: 0;
}

div.img-teaser {
  background-color: rgba(247, 233, 210, 1);
  cursor: pointer;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 21px;
  max-width: 800px;
  height: auto;
  min-height: 165px;
}

div.img-teaser:hover,
div.img-teaser:focus {
  background-color: rgba(242, 224, 195, 1);
}

@media screen and (max-width: 767px) {
  div.img-teaser {
    padding-bottom: 21px;
    width: 100%;
  }
}

@media screen and (min-width: 1040px) {
  div.img-teaser {
  }

  /* in order to prevent boxes to be higher than wide */
  div.img-galery {
    max-height: 170px;
  }
}

div.img-galery {
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-image: url('https://picsum.photos/300/200'); */
  /* background-image: url('~assets/img/kennzahlen.png'); */
  background-image: var(--image-url);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  top: 0;
  left: 0;
  -webkit-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}

h5 {
  /* font-size: 21px; line-height: 25px; */
  font-size: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

div.img-teaser > div.img-galery {
  position: relative;
  height: 230px;
  transform-origin: bottom;
}

div.img-teaser > h5 {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 25px;
}

div.img-teaser:hover > div.img-galery,
div.img-teaser:focus > div.img-galery {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

@media screen and (max-width: 1119px) {
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.img-teaser > div.img-galery {
    height: 205px;
  }
}

@media screen and (max-width: 767px) {
  h5 {
    font-size: 18px;
    line-height: 21px;
  }

  div.img-teaser > h5 {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
<template>
  <div>
    <div class="sharing">
      <label class="sharing">{{ $t('share_page') }}:</label>
      <a target="_blank" :href="shareLinkLinkedIn" class="linkedin-ico"></a>
      <a target="_blank" :href="shareLinkXing" class="xing-ico"></a>
      <a target="_blank" :href="shareLinkFacebook" class="fb-ico"></a>
      <a target="_blank" :href="shareLinkTwitter" class="tw-ico"></a>
      <a target="_blank" :href="shareLinkMailTo" class="email-ico"></a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    siteUrl() {
      return 'https://www.be.ch/gemfin'
    },
    shareLinkLinkedIn() {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${this.siteUrl}&title=&summary=&source=`
    },
    shareLinkXing() {
      return `https://www.xing.com/spi/shares/new?url=${this.siteUrl}`
    },
    shareLinkFacebook() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.siteUrl}`
    },
    shareLinkTwitter() {
      return `https://twitter.com/intent/tweet?url=${this.siteUrl}&text=`
    },
    shareLinkMailTo() {
      return `mailto:?&subject=Gemeindefinanzen Kanton Bern&body=${this.siteUrl}`
    },
  },
}
</script>

<style></style>

<template>
  <text :y="y">
    <tspan
      v-for="(f, i) in fragments"
      :key="`tspan-${i}`"
      :x="x"
      :dy="calcDy(i)"
      text-anchor="middle"
      alignment-baseline="hanging"
      >{{ f }}</tspan
    >
  </text>
</template>

<script>
export default {
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lineLength: 15,
    }
  },
  computed: {
    fragments() {
      const delimiter = '-'
      const tokens =
        this.content.length > this.lineLength
          ? this.content.split(delimiter)
          : [this.content]

      // reduce to 2 tokens, so we get only two lines and not three or more
      // e.g. Haut-Simmental - Gessenay
      // Haut-Simmental-
      // Gessenay

      // treat special cases
      if (this.content.includes('Bas-Simmental')) {
        return ['Frutigen-', 'Bas-Simmental']
      }
      if (this.content.includes('Haut-Simmental')) {
        return ['Haut-Simmental-', 'Gessenay']
      }

      // case tokens length <= 2
      // stick delimiter back to the words
      return tokens.map((t, i) => {
        return i < tokens.length - 1 ? t + delimiter : t
      })
    },
  },
  methods: {
    calcDy(i) {
      return i === 0 ? 0 : '1.23em'
    },
  },
}
</script>

<style></style>

<template>
  <div
    :id="containerId"
    class="container px-0 mt-3 float-left"
    style="min-height: 300px"
  >
    <div class="chart-header">
      <h3>{{ title }}</h3>
    </div>
    <transition name="fade">
      <svg
        v-if="show"
        :id="chartId"
        :width="svgWidth"
        :height="svgHeight"
        @mousemove="throttledMouseMoved"
        @mouseleave="mouseLeave"
      >
        <g id="myG" :transform="translate(bounds.left, bounds.top)">
          <circle
            v-for="(d, i) in data"
            :key="`circle-${i}`"
            v-b-tooltip="{
              title: tooltipContent(d),
              placement: 'top',
              html: true,
              customClass: 'circle-tooltip',
              id: `tooltip-${d.index}`,
              trigger: 'manual',
              animation: false,
              interactive: false,
            }"
            :cx="d.x"
            :cy="d.y"
            :r="bubbleRadius"
            :fill="getColor(d)"
            :stroke="getStrokeColor(d)"
            :stroke-width="getStrokeWidth(d)"
          />
          <WrappingSVGText
            v-for="(d, idx) in cPoints"
            :key="idx"
            class="chart-label primary"
            :x="d.x"
            :y="d.y + d.r"
            :content="d.name"
          />
        </g>
      </svg>
    </transition>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import WrappingSVGText from '@/components/util/WrappingSVGText'

import {
  translateString,
  xyCompare,
  widthCompare,
  dist,
  extractIso,
  generateUniqueId,
} from '@/plugins/utils'
import { createTooltip } from '@/plugins/tooltips'
import { formatPreciseNumber } from '@/plugins/format'
import {
  colorBlue,
  colorGray,
  colorRed,
  bubbleSelectStrokeColor,
} from '~/plugins/colors'
import { prop } from '~/plugins/accessors'
import { BUBBLE_HIGHLIGHT_STROKE_WIDTH } from '~/plugins/constants'

let sszvis

if (process.client) {
  sszvis = require('sszvis')
}

const COLUMN_VERWALTUNGSKREIS = 'Verwaltungskreis_Bezeichnung'
const COLUMN_GESAMTERGEBNIS = '90_Abschluss_Erfolgsrechnung'
const COLUMN_GEMEINDE = 'Gemeindename'

export default {
  components: {
    WrappingSVGText,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      minHeight: 200,
      svgWidth: 1,
      simulation: null,
      cPoints: [],
      debug: true,
      bubbleRadius: 4,
      collisionRadius: 6,
      bounds: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      quadtree: d3.quadtree().addAll([]),
      closestBubble: null,
      show: false,
    }
  },
  computed: {
    data() {
      return this.chartData
    },
    chartId() {
      return generateUniqueId('startseite/rechnungsergebnis')
    },
    unit() {
      return this.$i18n.t('chf_ew')
    },
    formatWithoutDecimals() {
      return formatPreciseNumber(0)
    },
    dataPath() {
      const iso = extractIso(this.$i18n)
      const localizedFolder = iso
      return `/data/rechnungsergebnis/${localizedFolder}/rechnungsergebnis.csv`
    },
    containerId() {
      return `${this.chartId}-container`
    },
    hashedContainerId() {
      return `#${this.containerId}`
    },
    viewDimensions() {
      return this.sortedDimensions
    },
    sortedDimensions() {
      const innerSorted = this.dimensions.map((d) => {
        d.points = d.points.sort(xyCompare)
        return d
      })
      const sorted = innerSorted.sort(widthCompare)
      return sorted
    },
    xBorder() {
      return 0
    },
    innerHeight() {
      return this.svgHeight - this.bounds.top - this.bounds.bottom
    },
    innerWidth() {
      return this.svgWidth - this.bounds.left - this.bounds.right
    },
    svgHeight() {
      const layoutPattern = this.responsiveLayoutPattern
      const ratio = layoutPattern.height / layoutPattern.width
      const w = this.svgWidth * ratio
      return w < this.minHeight ? this.minHeight : w
    },
    xScale() {
      const xValues = this.verwaltungskreise.map((vk) => {
        const pt = _.find(this.cPoints, (d) => {
          return d.name === vk
        })
        return pt ? pt.x : 0
      })
      return d3.scaleOrdinal().domain(this.verwaltungskreise).range(xValues)
    },
    yScale() {
      const lookup = this.cPointsLookup()
      const yValues = this.verwaltungskreise.map((d) => {
        return lookup[d] ? lookup[d].y : 0
      })
      return d3.scaleOrdinal().domain(this.verwaltungskreise).range(yValues)
    },
    verwaltungskreise() {
      // order by municipality count
      const vkCount = d3
        .nest()
        .key((d) => {
          return this.vkAcc(d)
        })
        .rollup(function (v) {
          return v.length
        })
        .entries(this.data)

      // sort descending
      const vkSorted = vkCount.sort((a, b) => {
        return b.value - a.value
      })

      return vkSorted.map((d) => {
        return d.key
      })
    },
    responsiveLayoutPattern() {
      const pattern = this.closestLayoutPattern(this.svgWidth)

      return pattern
    },
    basePositions() {
      // from the  layout pattern we need to create base positions
      const patternArray = this.obj2Array(this.responsiveLayoutPattern.lookup)
      const xExtent = [0, this.responsiveLayoutPattern.width]
      const yExtent = [0, this.responsiveLayoutPattern.height]

      const xScl = d3.scaleLinear().domain(xExtent).range([0, this.svgWidth])
      const yScl = d3
        .scaleLinear()
        .domain(yExtent)

        .range([0, this.svgHeight])

      const positions = patternArray.map((d) => {
        const xPos = xScl(d.value.x)
        const yPos = yScl(d.value.y)
        return {
          key: d.key,
          x: xPos,
          y: yPos,
        }
      })
      return positions
    },
    basePositionsLookup() {
      const lookup = {}
      _.each(this.basePositions, (d) => {
        lookup[d.key] = {
          name: d.key,
          x: d.x,
          y: d.y,
        }
      })
      return lookup
    },
    vkData() {
      const nestedData = d3
        .nest()
        .key((d) => {
          return this.vkAcc(d)
        })
        .rollup((v) => {
          return v.length
        })
        .entries(this.data)

      const readableArray = nestedData.map((d) => {
        return {
          verwaltungskreis: d.key,
          count: d.value,
        }
      })

      const sortedArray = _.sortBy(readableArray, (d) => {
        return d.count
      })

      // descending order
      return sortedArray.reverse()
    },

    vkPositionsArray() {
      return _.map(this.vkPositions, function (value, key) {
        return {
          verwaltungskreis: key,
          x: value[0],
          y: value[1],
        }
      })
    },
    gdeAcc() {
      return prop(COLUMN_GEMEINDE)
    },
    vkAcc() {
      return prop(COLUMN_VERWALTUNGSKREIS)
    },
    resultAcc() {
      return prop(COLUMN_GESAMTERGEBNIS)
    },
    xAcc() {
      return this.vkAcc
    },
    yAcc() {
      return this.vkAcc
    },
    vAcc() {
      return this.resultAcc
    },
    throttledMouseMoved() {
      return _.throttle(this.mouseMoved, 50)
    },
  },
  watch: {
    data(oldval, newval) {
      // initialise data positions
      const offset = 50
      _.each(this.data, (d) => {
        d.x = this.xScale(this.xAcc(d))
        d.y = this.yScale(this.yAcc(d))
        const val = this.vAcc(d)
        if (val > 0) {
          d.x -= offset
        } else if (val < 0) {
          d.x += offset
        }
      })
      this.cPoints = this.centerPoints()
      this.createSimulation()
      this.simulation.restart()
      this.quadtree = d3
        .quadtree()
        .x((d) => {
          return d.x
        })
        .y((d) => {
          return d.y
        })
        .addAll(this.data)
    },
    svgWidth(oldval, newval) {
      const offset = 50
      _.each(this.data, (d) => {
        d.x = this.xScale(this.xAcc(d))
        d.y = this.yScale(this.yAcc(d))
        const val = this.vAcc(d)
        if (val > 0) {
          d.x -= offset
        } else if (val < 0) {
          d.x += offset
        }
      })
      this.cPoints = this.centerPoints()
      this.createSimulation()
      this.simulation.restart()
      this.quadtree = d3
        .quadtree()
        .x((d) => {
          return d.x
        })
        .y((d) => {
          return d.y
        })
        .addAll(this.data)
    },
  },
  mounted() {
    sszvis.viewport.on('resize', this.resize)
    this.updateWidth()
    this.show = true
  },
  beforeDestroy() {
    sszvis.viewport.off('resize', this.resize)
  },
  methods: {
    closestLayoutPattern(w) {
      const closest = this.viewDimensions.reduce((a, b) => {
        const da = dist(w, a.width)
        const db = dist(w, b.width)
        return da < db ? a : b
      })

      //  create lookup
      const lookup = {}
      this.verwaltungskreise.forEach((d, i) => {
        lookup[d] = closest.points[i]
      })

      return {
        width: closest.width,
        height: closest.height,
        lookup,
      }
    },
    translate(x, y) {
      return translateString(x, y)
    },
    tooltipContent(d) {
      const formattedValue = this.formatWithoutDecimals(this.resultAcc(d))
      const valueWithUnit = `${formattedValue} ${this.unit}`
      return createTooltip(this.gdeAcc(d), valueWithUnit)
    },
    mouseLeave(event) {
      // hide all tooltips on leaving svg
      this.$root.$emit('bv::hide::tooltip')
    },
    mouseMoved(event) {
      const mx =
        event.clientX -
        document.getElementById(this.chartId).getBoundingClientRect().left

      const my =
        event.clientY -
        document.getElementById(this.chartId).getBoundingClientRect().top

      const closest = this.quadtree.find(mx, my, 10)

      if (closest == null) {
        this.$root.$emit('bv::hide::tooltip')
      } else {
        const id = 'tooltip-' + closest.index
        if (closest === this.closestBubble) {
          // same bubble as before, do nothing
        } else {
          // different bubble than before
          this.$root.$emit('bv::hide::tooltip')
          this.$root.$emit('bv::show::tooltip', id)
        }
      }
      // update closest bubble
      this.closestBubble = closest
    },
    cPointsLookup() {
      const lookup = {}
      _.each(this.cPoints, (d) => {
        lookup[d.name] = {
          x: d.x,
          y: d.y,
        }
      })
      return lookup
    },
    obj2Array(obj) {
      return _.map(obj, function (value, key) {
        return {
          key,
          value,
        }
      })
    },
    resize() {
      this.updateWidth()
    },
    updateWidth() {
      this.svgWidth = sszvis.measureDimensions(this.hashedContainerId).width
    },
    createSimulation() {
      if (this.simulation) {
        this.simulation.stop()
        this.simulation.nodes([])
        this.simulation = null
      }
      const xForceOffset = 10
      this.simulation = d3
        .forceSimulation(this.data)
        .force(
          'x',
          d3
            .forceX((d) => {
              let xpos = this.xScale(this.xAcc(d))
              const val = this.vAcc(d)
              if (val > 0) {
                xpos -= xForceOffset
              } else if (val < 0) {
                xpos += xForceOffset
              }
              return xpos
            })
            .strength(1)
        )
        .force(
          'y',
          d3
            .forceY((d) => {
              return this.yScale(this.yAcc(d))
            })
            .strength(1)
        )
        .force(
          'collision',
          d3
            .forceCollide((d) => {
              return 0.1 * this.collisionRadius
            })
            .strength(1)
        )
        .stop()
        .on('tick', () => {
          this.$forceUpdate()
        })

      // run simulation without collide to set inital positions well
      // for (var i = 0; i < 0; i++) {
      //   this.simulation.tick();
      // }
      // add collisiotn
      this.simulation.force(
        'collision',
        d3
          .forceCollide((d) => {
            return this.collisionRadius
          })
          .strength(1)
      )
      this.simulation.alpha(1).restart()
      this.simulation.stop()
      // run simulation again with proper collisions
      for (let j = 0; j < 160; j++) {
        this.simulation.tick()
      }
    },
    getColor(d) {
      const val = this.vAcc(d)
      if (val > 0) {
        return colorBlue
      } else if (val < 0) {
        return colorRed
      } else if (val === 0) {
        return colorGray
      } else return 'black'
    },
    getStrokeColor(d) {
      if (d === this.closestBubble) {
        return bubbleSelectStrokeColor
      } else return 'none'
    },
    getStrokeWidth(d) {
      if (d === this.closestBubble) {
        return BUBBLE_HIGHLIGHT_STROKE_WIDTH
      } else return 1
    },
    centerPoints() {
      const maxCount = d3.max(this.vkData, (d) => {
        return d.count
      })
      // largest verwaltungskreise gets mapped to r = 80
      const rScale = d3.scaleSqrt().domain([0, maxCount]).range([0, 80])

      const rPadding = 18
      const cpData = this.vkData.map((d, i) => {
        const obj = {
          name: d.verwaltungskreis,
          x: this.basePositionsLookup[d.verwaltungskreis].x,
          y: this.basePositionsLookup[d.verwaltungskreis].y,
          r: rScale(d.count) - 0.1 * d.count,
          areaR: rScale(d.count) + rPadding,
        }
        return obj
      })
      return cpData
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

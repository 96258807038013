<template>
  <svg
    id="Ebene_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 32 24"
    style="enable-background: new 0 0 32 24"
    xml:space="preserve"
  >
    <g :transform="rotationString">
      <path
        id="right-arrow-white"
        :fill="color"
        :stroke="color"
        d="M21,2.2c-0.4-0.4-1-0.4-1.4-0.1c-0.4,0.4-0.4,1-0.1,1.4l0,0l7.4,7.4H2.6c-0.6,0-1,0.4-1,1l0,0
	c0,0.6,0.5,1,1,1l0,0h24.3l-7.4,7.4c-0.4,0.4-0.4,1,0,1.5c0.4,0.4,1,0.4,1.4,0l0,0l9.1-9.1c0.4-0.4,0.4-1,0-1.4l0,0L21,2.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    direction: { type: String, default: 'up' },
    color: { type: String, default: 'black' },
  },
  computed: {
    rotationValue() {
      if (this.direction === 'up') {
        return -45
      } else if (this.direction === 'down') {
        return 45
      } else return 0
    },
    rotationString() {
      return `rotate(${this.rotationValue} 16 12)`
    },
  },
}
</script>

<style scoped>
path {
  stroke-width: 2px;
}
</style>

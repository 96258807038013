import * as d3 from 'd3'
import { locale } from '@/plugins/locale.js'

const timeParse = d3.timeFormatLocale(locale).parse
const yearParser = timeParse('%Y')
function parseYear(d) {
  return yearParser(d)
}

function parseNumber(d) {
  return d.trim() === '' ? NaN : +d
}

export { parseNumber, parseYear }

<template>
  <div class="container-xl main-container">
    <div v-if="debug" class="row">
      <div class="col">
        
        <BreakpointViewer v-if="debug" />
      </div>
    </div>
    <div class="row no-break">
      <div class="lead-container">
        <h1>{{ title }}</h1>
        <p class="lead">
          {{ lead }}
        </p>
      </div>
    </div>
    <div class="row no-break">
      <div class="col">
        <RechnungsergebnisChart
          :title="titleRechnungsergebnis"
          :dimensions="rechnungsergebnisViewDimensions"
          :chart-data="rechnungsergebnisData"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph1"></div>
      <div class="col-md-4">
        <MiniLineChart
          chart-id="miniline-ertrag"
          :chart-data="minilineErtragsueberschussData"
          :label="miniChart1Label"
          date-column="Jahr"
          value-column="90_Abschluss_Erfolgsrechnung"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph2"></div>
      <div class="col-md-4">
        <MiniLineChart
          chart-id="miniline-bilanz"
          :chart-data="minilineBilanzueberschussData"
          :label="miniChart2Label"
          date-column="Jahr"
          value-column="299_Bilanzueberschuss_fehlbetrag"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph3"></div>
      <div class="col-md-4">
        <MiniLineChart
          chart-id="miniline-netto"
          :chart-data="minilineNettoinvestitionenData"
          :label="miniChart3Label"
          date-column="Jahr"
          value-column="Nettoinvestitionen"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph4"></div>
      <div class="col-md-4">
        <TrendComponent
          :title="miniChart4Label"
          :change-in-percent="miniSelbstfinanzierungData.Vergleich_VJ"
          :value="miniSelbstfinanzierungData.Selbstfinanzierung"
          :unit="$t('chf_ew')"
          :show-trend-unit="true"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph5"></div>
      <div class="col-md-4">
        <TrendComponent
          :title="miniChart5Label"
          :change-in-percent="miniSelbstfinanzierungsgradData.Vergleich_VJ"
          :value="miniSelbstfinanzierungsgradData.Selbstfinanzierungsgrad"
          unit="%"
          :show-trend-unit="false"
        />
      </div>
    </div>
    <div class="row no-break">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedParagraph6"></div>
      <div class="col-md-4">
        <TrendComponent
          :title="miniChart6Label"
          :change-in-percent="miniNettozuflussData.Vergleich_VJ"
          :value="miniNettozuflussData.Nettozufluss_Finanzausgleich"
          unit="%"
          :show-trend-unit="false"
          :no-color="true"
        />
      </div>
    </div>
    <div class="row">
    
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-md-8" v-html="annotatedTail"></div>

    </div>
    <div class="row no-break mt-4">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <ImageTeaser
          :to="localePath('bilanz')"
          :label="$t('balance')"
          :image-url="bilanzBg"
          
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <ImageTeaser
          :to="localePath('erfolgsrechnung')"
          :label="$t('income_statement')"
          :image-url="erfolgsrechnungsBg"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <ImageTeaser
          :to="localePath('kennzahlen')"
          :label="$t('key_figures')"
          :image-url="kennzahlenBg"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <ImageTeaser
          :to="localePath('gemeindevergleich')"
          :label="$t('comparison')"
          :image-url="gemeindevergleichBg"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ExternalLinkComponent :links="outlinks" class="links-startpage" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <DownloadComponent
          :files="excelFiles"
          style-class="downloads-startpage"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <SocialMediaLinks />
      </div>
    </div>
  </div>
</template>

<script>
import TrendComponent from '@/components/charts/startpage/TrendComponent'
import RechnungsergebnisChart from '@/components/charts/startpage/RechnungsergebnisChart'
import MiniLineChart from '@/components/charts/startpage/MiniLineChart'
import BreakpointViewer from '@/components/debug/BreakpointViewer'
import SocialMediaLinks from '@/components/layout/SocialMediaLinks'
import ImageTeaser from '@/components/layout/ImageTeaser'
import ExternalLinkComponent from '@/components/layout/ExternalLinkComponent'
import DownloadComponent from '@/components/layout/DownloadComponent'
import { autocast, extractIso, injectAnnotations } from '~/plugins/utils'
import kennzahlenImg from '~/assets/img/kennzahlen.svg'
import bilanzImg from '~/assets/img/bilanz.svg'
import erfolgsrechnungsImg from '~/assets/img/erfolgsrechnung.svg'
import gemeindevergleichImg from '~/assets/img/gemeindevergleich.svg'

export default {
  components: {
    TrendComponent,
    RechnungsergebnisChart,
    MiniLineChart,
    BreakpointViewer,
    SocialMediaLinks,
    ImageTeaser,
    ExternalLinkComponent,
    DownloadComponent,
  },
  async asyncData({ app, $client, env, $content, $config: { apiSecret } }) {
    const iso = extractIso(app.i18n)

    let ctfResult = {
      title: '',
      lead: '',
      body: '',
      linkFinsta: {
        title: '',
        url: '',
        active: false,
      },
      linkMedienmitteilung: {
        title: '',
        url: '',
        active: false,
      },
    }

    if (process.server) {
      const client = $client
      ctfResult = await client.getRechnungsergebnis(iso)
    }

    const {
      title,
      lead,
      titleRechnungsergebnis,
      linkMedienmitteilung,
      linkFinsta,
      paragraph1,
      paragraph2,
      paragraph3,
      paragraph4,
      paragraph5,
      paragraph6,
      miniChart1Label,
      miniChart2Label,
      miniChart3Label,
      miniChart4Label,
      miniChart5Label,
      miniChart6Label,
      tail,
    } = ctfResult

    // rechnungsergebnis: config data
    const configDataPath = `config/rechnungsergebnis/rechnungsergebnis_view_dimensions`
    const contentConfigData = await $content(configDataPath).fetch()
    const rechnungsergebnisViewDimensions = contentConfigData.dimensions

    // rechnungsergebnis: chart data
    const dataPath = `data/startseite/rechnungsergebnis/${iso}/rechnungsergebnis`
    const contentData = await $content(dataPath).fetch()
    const rechnungsergebnisData = contentData.body.map((d, i) => {
      return autocast(d)
    })

    // mini charts
    const minilineErtragsueberschuss = await $content(
      `data/startseite/minicharts/${iso}/ertragsueberschuss`
    ).fetch()

    const minilineBilanzueberschuss = await $content(
      `data/startseite/minicharts/${iso}/bilanzueberschuss`
    ).fetch()

    const minilineNettoinvestitionen = await $content(
      `data/startseite/minicharts/${iso}/nettoinvestitionen`
    ).fetch()

    const miniSelbstfinanzierung = await $content(
      `data/startseite/minicharts/${iso}/selbstfinanzierung`
    ).fetch()

    const miniSelbstfinanzierungsgrad = await $content(
      `data/startseite/minicharts/${iso}/selbstfinanzierungsgrad`
    ).fetch()

    const miniNettozufluss = await $content(
      `data/startseite/minicharts/${iso}/nettozufluss`
    ).fetch()

    const minilineNettoinvestitionenData = minilineNettoinvestitionen.body

    const miniSelbstfinanzierungData = miniSelbstfinanzierung.body[0]
    miniSelbstfinanzierungData.Selbstfinanzierung =
      +miniSelbstfinanzierungData.Selbstfinanzierung
    miniSelbstfinanzierungData.Vergleich_VJ =
      +miniSelbstfinanzierungData.Vergleich_VJ
    const miniSelbstfinanzierungsgradData = miniSelbstfinanzierungsgrad.body[0]
    miniSelbstfinanzierungsgradData.Selbstfinanzierungsgrad =
      +miniSelbstfinanzierungsgradData.Selbstfinanzierungsgrad
    miniSelbstfinanzierungsgradData.Vergleich_VJ =
      +miniSelbstfinanzierungsgradData.Vergleich_VJ
    const miniNettozuflussData = miniNettozufluss.body[0]
    miniNettozuflussData.Nettozufluss_Finanzausgleich =
      +miniNettozuflussData.Nettozufluss_Finanzausgleich
    miniNettozuflussData.Vergleich_VJ = +miniNettozuflussData.Vergleich_VJ

    // inject annotations
    const annotatedParagraph1 = await injectAnnotations(
      paragraph1,
      $content,
      iso
    )
    const annotatedParagraph2 = await injectAnnotations(
      paragraph2,
      $content,
      iso
    )
    const annotatedParagraph3 = await injectAnnotations(
      paragraph3,
      $content,
      iso
    )
    const annotatedParagraph4 = await injectAnnotations(
      paragraph4,
      $content,
      iso
    )
    const annotatedParagraph5 = await injectAnnotations(
      paragraph5,
      $content,
      iso
    )
    const annotatedParagraph6 = await injectAnnotations(
      paragraph6,
      $content,
      iso
    )
    const annotatedTail = await injectAnnotations(tail, $content, iso)
    return {
      title,
      lead,
      titleRechnungsergebnis,
      rechnungsergebnisViewDimensions,
      rechnungsergebnisData,
      annotatedParagraph1,
      annotatedParagraph2,
      annotatedParagraph3,
      annotatedParagraph4,
      annotatedParagraph5,
      annotatedParagraph6,
      miniChart1Label,
      miniChart2Label,
      miniChart3Label,
      miniChart4Label,
      miniChart5Label,
      miniChart6Label,
      annotatedTail,
      linkMedienmitteilung,
      linkFinsta,
      minilineErtragsueberschussData: minilineErtragsueberschuss.body,
      minilineBilanzueberschussData: minilineBilanzueberschuss.body,
      minilineNettoinvestitionenData,
      miniSelbstfinanzierungData,
      miniSelbstfinanzierungsgradData,
      miniNettozuflussData,
    }
  },
  data() {
    return {
      debug: false,
    }
  },
  computed: {
    kennzahlenBg() {
      return kennzahlenImg
    },
    gemeindevergleichBg() {
      return gemeindevergleichImg
    },
    erfolgsrechnungsBg() {
      return erfolgsrechnungsImg
    },
    bilanzBg() {
      return bilanzImg
    },
    outlinks() {
      return [this.linkFinsta, this.linkMedienmitteilung]
    },
    excelFiles() {
      return [
        {
          name: this.$i18n.t('xls_rechnungsergebnis'),
          url: `/xls/rechnungsergebnis/${this.localizedFolder}/Rechnungsergebnis_Résultat_des_comptes.xlsx`,
        },
      ]
    },
    localizedFolder() {
      return extractIso(this.$i18n)
    },
  },
  mounted() {
    this.$appendAnnotationTooltip()
  },
  methods: {
    toggleDebug() {
      this.debug = !this.debug
    },
  },
}
</script>

// const bTag = '<b>'
// const bTagClose = '</b>'
const divContentTag = '<div class="sszvis-tooltip__content" >'

// hack to make header bold
const divHeadTag = '<div class="sszvis-tooltip__header">'
const strongTag = '<strong>'
const strongTagClose = '</strong>'
const divBodyTag = '<div class="sszvis-tooltip__body ">'
const divTagClose = '</div>'
const tableTag = "<table class='sszvis-tooltip__body__table'>"
const tableTagClose = '</table>'
const tbodyTag = '<tbody>'
const tbodyClose = '</tbody>'
const trTag = '<tr>'
const trTagClose = '</tr>'
const tdTag = '<td>'
const tdTagClose = '</td>'

// const innerBody =
//   "<table class='sszvis-tooltip__body__table'><tbody><tr><td>Jahr</td><td>2013</td></tr><tr><td>Einwohner</td><td>10452</td></tr></tbody></table>"

function tooltipHeader(title) {
  return [divHeadTag, strongTag, title, strongTagClose, divTagClose].join('')
}

function tooltipBody(value) {
  return [divBodyTag, value, divTagClose].join('')
}

function tooltipContent(header, body) {
  return [divContentTag, header, body, divTagClose].join('')
}
function createTooltip(title, value) {
  const header = tooltipHeader(title)

  const body = tooltipBody(value)

  // let secondRow = [divBodyTag, innerBody, divTagClose].join("");
  const content = tooltipContent(header, body)
  // return d.year + ' ' + d.value
  return content
}

function createTooltipTable(tableData) {
  // tableData is of form [[col1,col2],[col1,col2],[col1,col2]]
  const trsArr = tableData.map((row, i) => {
    const tdsArr = row.map((col) => {
      // return [tdTag, col, tdTagClose].join("");
      // make them strong except the last one
      // const emphasize = i < tableData.length - 1

      // correction
      // if the length is larger than 3, make the two last ones not strong
      let emphasize = i < tableData.length - 1
      if (tableData.length > 3) {
        emphasize = i < tableData.length - 2
      }
      if (emphasize) {
        return [tdTag, strongTag, col, strongTagClose, tdTagClose].join('')
      } else return [tdTag, col, tdTagClose].join('')
    })
    const tds = tdsArr.join('')
    return [trTag, tds, trTagClose].join('')
  })

  const trs = trsArr.join('')
  // console.log("trs", trs);

  const innerBody = [tableTag, tbodyTag, trs, tbodyClose, tableTagClose].join(
    ''
  )

  const secondRow = [divBodyTag, innerBody, divTagClose].join('')

  const tooltipContent = [
    divContentTag,
    // firstRow,
    secondRow,
    divTagClose,
  ].join('')

  return tooltipContent
}

function createTooltipId(chartId, index) {
  return `${chartId}-tooltip-${index}`
}

export { createTooltip, createTooltipTable, createTooltipId }

function prop(key) {
  return function (object) {
    return object[key]
  }
}

function identity(value) {
  return value
}
export { prop, identity }
